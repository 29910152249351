import { DesktopDatePicker, LocalizationProvider } from "@mui/lab"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from "@mui/material";
import { useEForm } from "./context";
import { format, isValid } from "date-fns";

const DateSelector = () => {

    const {state, dispatch} = useEForm();

    const handleChange = (newValue: Date | null) => {
        if (newValue && isValid(newValue)) {
            dispatch({ type: 'UPDATE', payload: { field: 'appraisal_date', value: format(newValue, "yyyy-MM-dd") }});
        } else {
            console.error('Invalid or incomplete date provided to handleChange');
        }    
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                    label="Appriasal Date"
                    inputFormat="MM/dd/yyyy"
                    value={state.appraisal_date}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    )
}

export default DateSelector;