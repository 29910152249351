import { AppBar, Toolbar, IconButton,Typography } from "@mui/material";
import { Settings, Logout, Book, PersonPinCircle, LogoutOutlined } from "@mui/icons-material";
import {Link} from "react-router-dom"
import { useAuth } from "../../context";
import { Box } from "@mui/system";

export default function Navbar(){

    const auth = useAuth();

    return(
        <AppBar position="static">
            <Toolbar sx={{display:'flex', justifyContent:'space-between'}}>
                {auth.user && 
                <>
                <Typography variant="h6">{auth.user.username}</Typography>
                <Box>
                <IconButton component={Link} to="/">
                    <PersonPinCircle />
                </IconButton>
                <IconButton component={Link} to="/log">
                    <Book />
                </IconButton>
                <IconButton component={Link} to="/settings">
                    <Settings />
                </IconButton>
                <IconButton onClick={() => auth.signout()}>
                    <LogoutOutlined />
                </IconButton>
                </Box>
                </>
                }
            </Toolbar>
        </AppBar>
    )
}