import {  CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { listExperience, patchExperience, updateExperience } from "../../api/log";
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";


export default function LogTable(){
    
    
    const [state, setState] = useState();
    const {enqueueSnackbar} = useSnackbar();


    const columns = [
        { field: 'id', headerName: 'ID', width: 0, hide: true },
        { field: 'appraisal_date', headerName: 'Date', width: 110, editable: true },
        {
          field: 'address',
          headerName: 'Address',
          width: 350,
        },
        {
          field: 'form',
          headerName: 'Form',
          width: 200,
        },
        {
          field: 'appraiser_tasks',
          headerName: 'Appraiser Tasks',
          type: 'number',
          width: 375,
        },
        {
          field: 'supervisor_tasks',
          headerName: 'Supervisor Tasks',
          width: 375
        },
        {
            field: 'appraiser_hours',
            headerName: 'Appraiser Hours',
            width: 200,
            editable: true
        },
        {
            field: 'supervisor_hours',
            headerName: 'Supervisor Hours',
            width: 200,
            editable: true
          },
      ];

    const handleCellEdit = (params, event, details) => {

        patchExperience(params.id, {[params.field]: params.value})
            .then((res) => {
                enqueueSnackbar('Experience Updated', {variant:'success'})
                console.log(res.data)
            })
            .catch((err) => {
                enqueueSnackbar('Something Went Wrong', {variant:'error'})
            })

    }

    useEffect(() => {
        listExperience()
            .then(res => {
                setState(res.data)
            })
    },[]);

    if(state){
        return(
            <div style={{ height: '75vh', width: '100%' }}>
                <DataGrid
                    rows={state}
                    columns={columns}
                    pageSize={10}
                    onCellEditCommit={handleCellEdit}
                />
            </div>
        )
    } else {
        return(
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }
}