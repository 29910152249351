import { Paper, CardContent, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { experienceMetrics } from "../../api/log";



export default function LogMetricCard(){

    const [metrics, setMetrics] = useState();

    useEffect(() => {
        experienceMetrics()
            .then(res => {
                setMetrics(res.data);
            })
    },[]);

    return(
        <Stack direction="row" spacing={2}>
        {metrics &&
        <>
            <Paper sx={{p:3}} >
                <Typography variant="subtitle1">Total Hours</Typography>
                <Typography variant="h6" >{metrics.total_appraiser_hours}</Typography>
            </Paper>
            <Paper sx={{p:3}}>
                <Typography variant="subtitle1">Total Items</Typography>
                <Typography variant="h6" >{metrics.total_items}</Typography>
            </Paper>
        </>
        }
        </Stack>
    )
}