import {FormControlLabel,Box, Checkbox, Typography } from "@mui/material";
import React, { ChangeEvent, EventHandler } from "react";
import { useEForm } from "./context";

export interface Task {
    name: string,
    id: string
}

const appraiserTasks: Task[] = [
    {name:'Site Inspection & Descriptions', id:'1'},
    {name:'Bldg Inspection & Descriptions', id:'2'},
    {name:'Nbhd Description & Analysis', id:'3'},    
    {name:'Highest & Best Use Analysis', id:'4'},    
    {name:'Research Of Comp Sales & Analysis', id:'5'},    
    {name:'Income Approach *', id:'6'},    
    {name:'Cost Approach *', id:'7'},    
    {name:'Sales Approach *', id:'8'},    
    {name:'* (1-5 + 1 or 2 Approaches)', id:'9'},    
    {name:'* (1-5 + ALL (3) Approaches)', id:'10'},    
    {name:'Final Reconciliation', id:'11'},    
    {name:'Prepared Report Opinion - Appraisal Report', id:'12-a'},    
    {name:'Prepared Report Opinion - Restricted Appraisal Report', id:'12-b'},    
    {name:'Prepare a Workfile', id:'13'},    
    {name:'Field Review', id:'14'},    
    {name:'Desk Review', id:'15'},    
]

const supervisorTasks: Task[] = [
    {name:'Instructions For (a.1-13)(b.14)(c.15)', id:'16'},
    {name:'Inspect Subject Property', id:'17-a'},
    {name:'Inspect Comparables', id:'17-b'},
    {name:'Review Appraisal Report', id:'18-a'},
    {name:'Review Workfile', id:'18-b'},
]

interface Props {
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void,
    valuesList: string[],
    label: string
}

export const AppraiserTaskSelector = () => {
    
    const {state, dispatch} = useEForm();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            dispatch({type:"ADD_APPRAISER_TASK", payload:{field:"appraiser_tasks", value: event.target.value}});
        } else {
            dispatch({type:"REMOVE_APPRAISER_TASK", payload:{field:"appraiser_tasks", value: event.target.value}});

        }
    }

    return(
        <Box>
            <Typography>Appraiser Tasks</Typography>
            {appraiserTasks.map((task) =>
                <FormControlLabel 
                    control={<Checkbox name="appraiserTasks" checked={state.appraiser_tasks!.includes(task.id)} 
                    onChange={handleChange} 
                    value={task.id}/>} 
                    label={task.name}  
                />
            )}
        </Box>
    )
}

export const SupervisorTaskSelector= () => {
    const {state, dispatch} = useEForm();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            dispatch({type:"ADD_SUPERVISOR_TASK", payload:{field:"supervisor_tasks", value: event.target.value}});
        } else {
            dispatch({type:"REMOVE_SUPERVISOR_TASK", payload:{field:"supervisor_tasks", value: event.target.value}});

        }
    }
    return(
        <Box>
            <Typography>Supervisor Tasks</Typography>
            {supervisorTasks.map((task) =>
                <FormControlLabel 
                    control={<Checkbox name="supervisorTasks" checked={state.supervisor_tasks!.includes(task.id)} 
                    onChange={handleChange} 
                    value={task.id}/>} 
                    label={task.name}  
                />
            )}
        </Box>
    )
}






