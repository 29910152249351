import { accordionActionsClasses } from "@mui/material";
import {createContext, useContext, useReducer} from "react"

enum EFormActionKind {
    UPDATE = 'UPDATE',
    ADD_APPRAISER_TASK = 'ADD_APPRAISER_TASK',
    ADD_TASK_ARRAY = 'ADD_TASK_ARRAY',
    REMOVE_APPRAISER_TASK = 'REMOVE_APPRAISER_TASK',
    ADD_SUPERVISOR_TASK = 'ADD_SUPERVISOR_TASK',
    REMOVE_SUPERVISOR_TASK = 'REMOVE_SUPERVISOR_TASK',
    RESET = 'RESET'
}

interface EFormAction {
    type: EFormActionKind;
    payload: {field: string, value: string | string[]};
  }



const intialEFormState: EFormState = {
    appraisal_date: null,
    address:"",
    form:"",
    appraiser_tasks: [],
    supervisor_tasks: [],
    appraiser_hours:0,
    supervisor_hours: 0      
}

const formReducer = (state: EFormState, action: EFormAction) => {
    
    const {type, payload} = action;
    switch(type){
        case EFormActionKind.UPDATE:
            return {
                ...state, 
                [payload.field]: payload.value
            }

        case EFormActionKind.ADD_TASK_ARRAY:
            return {
                ...state,
                [payload.field]: payload.value
            }

        case EFormActionKind.ADD_APPRAISER_TASK:
            return {
                ...state,
                [payload.field]: [...state.appraiser_tasks, payload.value ]
            }



        case EFormActionKind.REMOVE_APPRAISER_TASK:
            return {
                ...state,
                [payload.field]: state.appraiser_tasks.filter(i => i !== payload.value)
            }
        case EFormActionKind.ADD_SUPERVISOR_TASK:
            return {
                ...state,
                [payload.field]: [...state.supervisor_tasks, payload.value ]
            }

        case EFormActionKind.REMOVE_SUPERVISOR_TASK:
            return {
                ...state,
                [payload.field]: state.supervisor_tasks.filter(i => i !== payload.value)
            }
        
        case EFormActionKind.RESET:
            return {
                ...intialEFormState
            }

        default:
            return state
    }
}

const formContext = createContext<{
    state: EFormState;
    dispatch: React.Dispatch<any>;
  }>({
      state: intialEFormState,
      dispatch: () => null

  });


export const EFormContextProvider:React.FC = ({children}) => {

    const [state, dispatch] = useReducer(formReducer, intialEFormState);

    return (
        <formContext.Provider value={{state, dispatch}}>{children}</formContext.Provider>
    )

}

export const useEForm = () => {
    return useContext(formContext)
}