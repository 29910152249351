import { authRequest } from ".";

const logEndpoints = {
    viewset_endpoint: 'log/viewset/'
}

/**
 * Function to post new data to experience log model
 */
export const createExperience = (data) => {
    return authRequest.post(logEndpoints.viewset_endpoint, data)
}

export const patchExperience = (id,data) => {
    return authRequest.patch(logEndpoints.viewset_endpoint+`${id}/`, data)
    
    
    
}

export const listExperience = () => {
    return authRequest.get(logEndpoints.viewset_endpoint)
}

export const experienceMetrics = () => {
    return authRequest.get('log/metrics')
}