import Form from "./pages/Form";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Settings from "./pages/Settings";
import Login from "./pages/Auth";
import DefaultLayout from "./components/Layout";
import Log from "./pages/Log";
import {useAuth} from "./context";
import EForm from "./components/Form";
import { ProtectedRoute } from "./routing";
import LoadingPage from "./pages/Loading";
import { useEffect } from "react";


function App() {

  const auth = useAuth();


  if(auth.authResolved){
    return(
    <Router>
    <DefaultLayout>
      <ProtectedRoute path="/log">
        <Log />
      </ProtectedRoute>
      <ProtectedRoute exact path="/">
        <EForm />
      </ProtectedRoute>
      <Route path="/login" component={Login} />
    </DefaultLayout>
    </Router>
  )} else {
    return(
      <LoadingPage /> 
    )
  }
}

export default App;
