import React, { ReactNode, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context"




export const ProtectedRoute = ({children, ...rest}) => {

    const auth = useAuth();

    
    return(
        <Route
            {...rest}
            render={({location}) => 
                auth.user ?
                (children) 
                : (<Redirect to="/login" />)
        
        }
        

        />
    )
    

}