import { useEffect } from "react";
import { useContext, createContext, useState } from "react";
import {authLogin, authLogout, authPing} from "../api/index"

const authContext = createContext();

export function ProvideAuth({children}){

    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>

}

export const useAuth = () => {
    return useContext(authContext);
}

const useProvideAuth = () => {
    const [user, setUser] = useState(null);
    const [authResolved, setAuthResolved] = useState(false)

    const signin = (username, password) => {
        return authLogin(username, password).then(res => {
            setUser(res);
            setAuthResolved(true);
        });
    }

    const signout = () => {
        authLogout();
        setUser(false);
        setAuthResolved(true)
    }

    const authCheck = () => {
        authPing()
            .then(res => {
                setUser(res.data)
                setAuthResolved(true)
            })
            .catch(err => {
                setUser(null)
                setAuthResolved(true)

            });
    }

    useEffect(() => {

        authCheck(); 

    },[]);

    return {
        user,
        authResolved,
        authPing,
        signin,
        signout
    }


}