import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { listExperience } from "../../api/log";
import { LogMetricCard, LogTable } from "../../components/Log";

export default function Log(){


    return(
    <Stack spacing={3}>
        <LogMetricCard />
        <LogTable />
    </Stack>
    )
}