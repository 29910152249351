import DateSelector from "./DateSelector"
import { Button, TextField } from "@mui/material"
import { useEForm } from "./context"
import { ChangeEvent } from "react"
import { createExperience } from "../../api/log"
import FormSelector from "./FormSelector"
import { QuickFillBtnGroup } from "./QuickFillBtns"
import { useSnackbar } from "notistack"


const EFormInputs = () => {

    const {state, dispatch} = useEForm();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({type:"UPDATE", payload: {field:e.target.name, value: e.target.value}})
    }
    
    return(
        <>
            <DateSelector />
            <TextField name="address" label="Address" onChange={handleInputChange} value={state.address}/>
            <QuickFillBtnGroup />
            <TextField name="appraiser_hours" label="Appraiser Hours" onChange={handleInputChange} value={state.appraiser_hours}/>
            <TextField name="supervisor_hours" label="Supervisor Hours" onChange={handleInputChange} value={state.supervisor_hours}/>
            <FormSelector />
        </>
    )

}

export const SubmitBtn = () => {

    const {state, dispatch} = useEForm();
    const {enqueueSnackbar} = useSnackbar()

    const handleSubmit = () => {
        createExperience(state)
            .then(res => {
                enqueueSnackbar("Success", {variant:"success"});
                dispatch({type: "RESET"})
                
            })
            .catch(err => {
                enqueueSnackbar("Something Went Wrong", {variant:"error"});
            })
    }

    return(
        <Button onClick={() => handleSubmit()}>Submit</Button>
    )
}

export default EFormInputs