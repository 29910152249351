import { Stack, TextField, Button } from "@mui/material"
import { Axios } from "axios"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { authLogin } from "../../api"
import { useAuth } from "../../context"

interface State {
    username:string,
    password:string
}

const Login = () => {

    const history = useHistory();
    const auth = useAuth();

    const [form, setForm]= useState<State>({
        username:"",
        password:""
    });

    const handleSubmit = () => {
        auth.signin(form.username, form.password)
            .then(() => {
                history.push('/log')
            })
            .catch(() => {
                alert("something went wrong")
            })
    }



    return(
        <Stack spacing={3}>
            <TextField onChange={(e) => setForm({...form, username: e.target.value})} label="Username" name="username"/>
            <TextField onChange={(e) => setForm({...form, password: e.target.value})} label="Password" name="password" type="password"/>
            <Button onClick={() => handleSubmit()} variant="contained">Login</Button>
        </Stack>
    )
}

export default Login;