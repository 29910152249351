import { Typography, AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";

const LoadingPage = () => {

    return(
        <>
        <AppBar position="static">
            <Toolbar></Toolbar>
        </AppBar>
        </>

    )

}

export default LoadingPage;