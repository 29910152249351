import axios from "axios";

const baseURL =  process.env.REACT_APP_API_HOST || 'http://localhost:8000';


export const apiRequest = axios.create({
    baseURL: baseURL
});

export const authRequest = axios.create({
    baseURL: baseURL,
    headers: {
        'Authorization': `Token ${window.localStorage.getItem('token')}`,
        'Content-Type': 'application/json',

    }

});


export const authLogout = () => {
    window.localStorage.removeItem('token');
    authRequest.defaults.headers['Authorization'] = "";
}

export const authLogin = (username, password) => {
    return apiRequest.post("user/auth_token", {"username":username, "password": password}, {"Content-Type":"application/json"})
        .then(res => {
            window.localStorage.setItem('token',res.data.token);
            authRequest.defaults.headers['Authorization'] = `Token ${res.data.token}`;
            return Promise.resolve(res.data);
        })
        .catch(err => {
            return Promise.reject(err);
        });
}


/**
 * simple authenticated ping to the server to test if user has valid auth token
 */
export const authPing = () => {

    return authRequest.post("user/auth_ping");
        
}
