import { Box,FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useEForm } from "./context"
import { ChangeEvent} from "react";
import { SelectChangeEvent } from '@mui/material/Select';

const FormSelector: React.FC<{}> = () => {

    const {state, dispatch} = useEForm();


    const forms = [
        "2055 Exterior",
        "GP Residential",
        "GP Land",
        "URAR 1004",
        "FNMA 1004D",
        "Condo 1073",
        "One Unit Field Review"
    ]
    const propertyTypes = [
        "Residential",
        "Commercial"
    ]

    const handleChange = (event: SelectChangeEvent) => {
        
        dispatch({type:"UPDATE", payload: {field:"form", value: event.target.value as string}})
    }

    return(
        <Box>
        <FormControl fullWidth>
            <InputLabel id="form-selector-label">Form</InputLabel>
            <Select
                labelId="form-selector-label"
                id="form-selector"
                name="form"
                value={state.form!}
                onChange={handleChange}
            >
                {propertyTypes.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                {forms.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>
        </FormControl>
        </Box>
    )
}

export default FormSelector;