import { Box } from "@mui/system";
import Navbar from "./Navbar";

const DefaultLayout: React.FC<{children: React.ReactNode[]}> = ({children}) => {
    return(
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Navbar />
            <Box sx={{p:2}}>{children}</Box>
        </Box>
    )
}

export default DefaultLayout;