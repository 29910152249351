import { Button, Stack } from "@mui/material"
import { Box } from "@mui/system";
import { format } from "date-fns";
import { useEForm } from "./context"

interface QuickFillInfo {
    form: string,
    appraiser_hours: number,
    supervisor_hours: number,
    appraiser_tasks: string[] | null,
    supervisor_tasks: string [] | null

}

interface Props {
    quick_fill_info: QuickFillInfo,
    label: string
}

const QuickFillBtn: React.FC<Props> = ({quick_fill_info, label}) => {

    const {dispatch} = useEForm();


    const handleClick = () => {
        dispatch({type: "UPDATE", payload: {field: "form", value: quick_fill_info.form}})        
        dispatch({type: "UPDATE", payload: {field: "appraiser_hours", value: quick_fill_info.appraiser_hours}})        
        dispatch({type: "UPDATE", payload: {field: "supervisor_hours", value: quick_fill_info.supervisor_hours}})        

        dispatch({type: "ADD_TASK_ARRAY", payload: {field:'appraiser_tasks', value: quick_fill_info.appraiser_tasks}})
        dispatch({type: "ADD_TASK_ARRAY", payload: {field:'supervisor_tasks', value: quick_fill_info.supervisor_tasks}})

    }

    return (
        <Button variant="outlined" onClick={() => handleClick()}>{label}</Button>
    )

}

const simpleQuickFills: Props[] = [
    {label:"Basic URAR", quick_fill_info: {form:"URAR 1004", appraiser_hours:8, supervisor_hours:3, appraiser_tasks: ["9","11","12-a","13"], supervisor_tasks:["16","17-a","18-a","18-b"]}},
    {label:"Condo", quick_fill_info: {form:"Condo 1073", appraiser_hours:6, supervisor_hours:2, appraiser_tasks: ["9","11","12-a","13"], supervisor_tasks:["16","17-a","18-a","18-b"]}},
    {label:"GP Res", quick_fill_info: {form:"GP Residential", appraiser_hours:8, supervisor_hours:3, appraiser_tasks: ["9","11","12-a","13"], supervisor_tasks:["16","17-a","18-a","18-b"]}},
    {label:"GP Land", quick_fill_info: {form:"GP Land", appraiser_hours:8, supervisor_hours:3, appraiser_tasks: ["9","11","12-a","13"], supervisor_tasks:["16","17-a","18-a","18-b"]}},
    {label:"Exterior", quick_fill_info: {form:"2055 Exterior", appraiser_hours:6, supervisor_hours:3, appraiser_tasks: ["9","11","12-a","13"], supervisor_tasks:["16","18-a","18-b"]}},
    {label:"Update", quick_fill_info: {form:"FNMA 1004D", appraiser_hours:4, supervisor_hours:2, appraiser_tasks: ["9","11","12-a","13"], supervisor_tasks:["16","18-a","18-b"]}},
    {label:"Field Review", quick_fill_info: {form:"One Unit Field Review", appraiser_hours:6, supervisor_hours:2, appraiser_tasks: ["14"], supervisor_tasks:["17-a","18-a","18-b"]}},
    {label:"Admin Review", quick_fill_info: {form: "Residential", appraiser_hours: 2, supervisor_hours:0, appraiser_tasks: ["15"], supervisor_tasks: []}}


]

export const QuickFillBtnGroup = () => {
    return(
        <Stack direction="row" spacing={2} >
            {simpleQuickFills.map((q) =>
                <QuickFillBtn {...q} />
            )}

        </Stack>
    )
}