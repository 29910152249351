import { Button, Stack, Typography } from "@mui/material";
import { EFormContextProvider } from "./context";

import EFormInputs, { SubmitBtn } from "./Inputs";
import { AppraiserTaskSelector, SupervisorTaskSelector } from "./TaskSelector";

const EForm:React.FC<{}> =() =>{


    return(
        <EFormContextProvider>
        <Stack spacing={3}>
            <EFormInputs />
            <AppraiserTaskSelector />
            <SupervisorTaskSelector />
            <SubmitBtn />
        </Stack>
        </EFormContextProvider>
    )
}

export default EForm;